













import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class GoogleMap extends Vue {
  center: any = { lat: 51.1097031, lng: 17.0640078 };
  markers: any = [{position: { lat: 51.1097031, lng: 17.0640078 }}];
  places: any = [];
  currentPlace;

  mounted() {
    //this.geolocate();
  }

  setPlace(place: any) {
    this.currentPlace = place;
  }

  addMarker() {
    if (this.currentPlace) {
      const marker = {
        lat: this.currentPlace ? this.currentPlace.geometry.location.lat() : 0,
        lng: this.currentPlace ? this.currentPlace.geometry.location.lng() : 0,
      };
      this.markers.push({ position: marker });
      this.places.push(this.currentPlace);
      this.center = marker;
      this.currentPlace = null;
    }
  }

  geolocate() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
  }
}
