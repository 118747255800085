





























import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ContactSide extends Vue {
  public headerItems = [
    {
      width: "250px",
      iconName: "phone-alt",
      text: "792 045 333 </br> 536 796 043"
    },
    { width: "250px", iconName: "at", text: "lab4tox@lab4tox.pl" },
    {
      width: "250px",
      iconName: "map-marker-alt",
      text: "ul. M. Skłodowskiej-Curie 55/61 <br> 50-369 Wrocław pok.121"
    },
    {
      width: "250px",
      iconName: { prefix: "fab", iconName: "facebook" },
      text: "facebook.com/lab4tox"
    },
    {
      width: "250px",
      iconName: "fax",
      text: "FAX: (+48) 71 750 60 87"
    }
  ];
}
