<template>
  <div class="contact-page">
    <div class="contact-card">
      <h1 class="contact-card-header">{{ $t("contactConter.header") }}</h1>
      <div class="contact-content-wrapper">
        <GoogleMap/>
        <ContactSide/>
      </div>
    </div>
    <PaymentsInfo/>
  </div>
</template>

<script>
import GoogleMap from "@/components/GoogleMap.vue";
import ContactSide from "@/components/ContactSide.vue";
import PaymentsInfo from "@/components/PaymentsInfo.vue";

export default {
  name: "ContactPage",
  components: {
    GoogleMap,
    ContactSide,
    PaymentsInfo
  }
};
</script>

<style>
.contact-card-header {
  font-size: 45px;
  padding-bottom: 20px;
  margin: 60px 0 40px 0;
  position: relative;
}
.contact-card-header::after {
  content: "";
  height: 3px;
  width: 300px;
  background: #96b7fe;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.contact-card {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1250px;
  margin: auto;
  background-color: white;
}

.contact-content-wrapper {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1024px) {
  .contact-card {
    width: 100%;
  }
  .contact-content-wrapper {
    flex-direction: column;
  }
  .contact-card-header {
    font-size: 36px;
    line-height: 50px;
    padding-bottom: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .contact-side-container {
    width: 100%;
    margin: 10px 0;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }
  .contact-open-hours-wrapper {
    width: 100%;
  }
  .contact-payments-header {
    font-size: 36px;
    line-height: 50px;
    padding-bottom: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
  }
  .contact-payments-content-wrapper {
    flex-direction: column;
  }
  .contact-payments {
    width: 150px;
    height: 150px;
    margin-bottom: 15px;
  }
  .contact-payments-info-container {
    width: 85%;
  }
  .contact-payments-info-container p {
    font-size: 22px;
  }
}
</style>
