























import { Component, Vue } from "vue-property-decorator";
import { Payments2Ilustration } from "./ilustrations";

@Component({
  components: {
    Payments2Ilustration
  }
})
export default class PaymentsInfo extends Vue {}
